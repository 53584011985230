$('.needs-validation').each(function (i, el) {
    const fields = $(el).find('.required');
    $(el).on('submit', function (e) {
        fields.each(function(k, field) {
            const elJ = $(field);
            const parent = elJ.parent();
            const value = field.value;

            if (value == '') {
                return parent.addClass('invalid');
            }
            if (field.type === 'email' && !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(field.value))) {
                return parent.removeClass('invalid').addClass('invalid-email')
            }

            if (elJ.data('cpf-cnpj')) {

            }

            return parent.removeClass('invalid');
        });
        e.stopPropagation();
        e.preventDefault();
    })
});
