require('./bootstrap');
require('./customer-central');
require('./contact');
require('./validation');

require('./plans-component');
require('./popup-cookies');
require('./popup-contact');
require('./carrousel');
require('./posts');
