const { getCookie, setCookie } = require('./cookie');

if ($(".contact-form").length) {
    const popup = document.createElement('div');
    popup.setAttribute('class', 'popup-cookie');

    const popupContent = `
        <style>
            .popup-cookie {
                background-color: rgba(33, 41, 52, 0.75);
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                height: 100vh;
                width: 100vw;
                overflow: hidden;
                color: rgb(20, 30, 35);
                position: fixed;
                top: 0px;
                left: 0px;
                z-index: 2147483647;
            }

            .popup-cookie-content {                
                margin: 0 15px;
                background: rgb(255, 255, 255);
                width: 100%;
                display: grid;
                grid-template-rows: none;
                justify-items: center;
                position: relative;
                border-radius: 10px;
            }

            .popup-cookie-content p {
                line-height: 1.5em;
                margin-bottom: 15px;
                max-height: none;
            }

            .popup-cookie-content .text-container {
                margin-bottom: 1.6rem;
                margin-top: 1.6rem;
            }

            @media screen and (min-width: 768px) {
                .popup-cookie-content {
                    height: auto;
                    max-height: none;
                    width: 770px;
                    grid-template-rows: auto 1fr;
                }

                .popup-cookie-content p {
                    margin-bottom: 0px;
                    max-height: none;
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 48em) {
                .section-actions {
                    display: block !important;
                    margin-bottom: 0;
                }

                .section-actions .btn-first {
                    margin-bottom: 1rem;
                }

                .logo-container {
                    padding-top: 40px;
                }
            }

            .logo-container {
                padding-top: 5px;
            }

            .section-actions {
                display: flex;
                margin-bottom: 10px;
            }

            .popup-cookie-content {
                padding: 10px 30px;
            }

            .popup-cookie-title {
                font-size: 1.3em;
                text-align: center;
                color: var(--color-primary);
            }

            .color-hover-opacity {
                opacity: 0.9;
                transition: opacity 0.5s;
            }

            .color-hover-opacity:hover {
                opacity: 1;
            }
        </style>
        <div class="popup-cookie-content">
            <div class="section">
                <div class="logo-container">
                    <h2 class="popup-cookie-title">
                        Seus dados preenchidos serão utilizados apenas para pré-cadastro.
                    </h2>
                </div>

                <div class="text-container">
                    <p style="margin-top: 15px;">
                        A INNON não vai compartilhar seus dados com terceiros.
                        Para conhecer nossa Política de Privacidade visite nosso site.
                    </p>
                </div>
            </div>
            <div class="section section-actions" style="width: 100%;">
                <div class="col-12 col-md-6">
                <button class="color-hover-opacity not-accept btn-first" style="margin-left: 10px;">Não aceito</button>
                </div>
                <div class="col-12 col-md-6">
                <button class="color-hover-opacity cookie-accept btn-second" style="margin-left: 10px;">Aceito</button>
                </div>
            </div>
        </div>
    `;

    popup.innerHTML = popupContent;

    $('.containt-contact').append(popup);

    $('.contact-form').css({display: 'block'});

    $('.cookie-accept').on('click', function () {
        setCookie('glpd-accept', 1, 2000);
        $('.popup-cookie').css({display: 'none'});
    });

    $('.not-accept').on('click', function () {
        setCookie('glpd-accept', 0, 2000);
        $('.popup-cookie').css({display: 'none'});
        $('form input[type="text"]').prop("disabled", true);
        $('form input[type="email"]').prop("disabled", true);
        $("textarea").prop('disabled', true);
        $('form input[type="submit"]').prop("disabled", true);
    })

}
