//var emps = ['stlk', 'innon', 'cellsite', 'lore', 'acs', 'iadir', 'compass', 'lfg', 'jrsz', 'mvb', 'maq'];
var cont_emps = 0;

var obj = "";

var emps = ['m79', 'mvb', 'stlknew', 'maq','lore', 'lfg', 'jrsz', 'innonnew', 'iadir', 'cl', 'compass', 'cellsite', 'acs', 'acs2', 'maq2'];

function isValidCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
    ) {
        return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(9, 10)) ) return false
    soma = 0
    for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(10, 11) ) ) return false
    return true
}

function verificarCPF(cpf){
    $("#customer-center").text("Carregando...");
    var timeOut = setTimeout(function () {
        $("#customer-center").text("Acessar Central do Cliente");
        clearTimeout(timeOut);
    }, 5000);
    // var cpf = $("#cpf_cliente").val();
    // var tipo = emps[cont_emps];
    // obj = obj_p ? obj_p : obj;

    // if(tipo){

    //     $(obj).val("Carregando...");

    //     $.get("https://innon.com.br/php/consulta-cpf.php?&cpf="+cpf, {}, function(ret){

    //         if(ret == "true"){

    //             cont_emps = 0;

    //             $(obj).val("Acessar Central do Cliente");

    //             window.open("http://"+tipo+".innon.com.br/central/");

    //         }else{

    //             cont_emps++;

    //             verificarCPF();

    //         }

    //     }).fail(function(){

    //         setTimeout("verificarCPF()", 2000);

    //     });

    // }else{

    //     $(obj).val("Acessar Central do Cliente");

    //     alert("CPF nÃ£o encontrado.");

    //     cont_emps = 0;

    // }

}

function valid(field) {
    const elJ = $(field);
    const parent = elJ.parent();
    const value = field.value;

    if (value == '') {
        parent.addClass('invalid');
        return false;
    }

    if (field.type === 'email' && !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(field.value))) {
        parent.removeClass('invalid').addClass('invalid-email');
        return false;
    }

    if (elJ.data('cpf-cnpj') && !isValidCPF(field.value)) {
        parent.removeClass('invalid').addClass('invalid-cpf');
        return false;
    }
    parent.removeClass('invalid').removeClass('invalid-email').removeClass('invalid-cpf');
    return true;
}

$('.needs-validation-customer').each(function (i, el) {
    const fields = $(el).find('.required');
    $(el).on('submit', function (e) {
        e.stopPropagation();
        e.preventDefault();
        let validFields = true;
        for(var i = 0; i < fields.length;i++) {
            validFields = valid(fields[i]);
        }
        if (validFields) {
            verificarCPF($('#cpf_cliente').val().replace(/\D/g, ''));
        }
    })
});


$('#cpf_cliente').on('keydown', function (e) {

    var digit = e.key.replace(/\D/g, '');

    var value = $(this).val().replace(/\D/g, '');

    var size = value.concat(digit).length;

    $(this).mask((size <= 11) ? '000.000.000-00' : '00.000.000/0000-00');
});
