const { getCookie, setCookie } = require('./cookie');

if ($(".customer-central-form").length > 0 && getCookie('glpd-accept') !== '1') {
    const popup = document.createElement('div');
    popup.setAttribute('class', 'popup-cookie');

    const popupContent = `
        <style>
            .popup-cookie {
                width: 100vw;
                overflow: hidden;
                position: fixed;
                bottom: 0px;
                left: 0px;
                z-index: 2147483649 !important;
            }

            .popup-cookie-content {
                background: var(--color-primary);
                height: 95%;
                width: 100%;
                display: flex;
            }

            .popup-cookie-content p {
                line-height: 1.5em;
                margin-bottom: 15px;
                max-height: none;
            }

            .popup-cookie-content .text-container {
                margin-bottom: 1.6rem;
                margin-top: 1.6rem;
            }

            @media screen and (min-width: 768px) {
                .popup-cookie-content {
                    height: auto;
                    max-height: none;
                    grid-template-rows: auto 1fr;
                }

                .popup-cookie-content p {
                    margin-bottom: 0px;
                    max-height: none;
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 48em) {
                .popup-cookie-content {
                    display: block;
                }

                .popup-cookie-content .section-actions {
                    margin-top: 15px;
                }
            }

            .logo-container {
                padding-top: 5px;
            }

            .section-actions {
                display: flex;
            }

            .popup-cookie-content {
                padding: 20px 40px;
            }

            .popup-cookie-title {
                font-size: 1.1em;
                color: #fff;
                margin-bottom: 0px;
            }

            .color-hover-opacity {
                opacity: 0.9;
                transition: opacity 0.5s;
            }

            .color-hover-opacity:hover {
                opacity: 1;
            }
            .color-hover-opacity.cookie-accept {
                width: 200px;
                margin-bottom: 0px;
                font-size: 1rem;
            }

            .policy {
                color: #fff;
                text-decoration: underline;
            }

            .policy:hover {
                color: #fff;
            }
            #sz4IframeForm {
                transition: .5s margin-bottom;
            }
            #sz4IframeForm.cookie-mb {
                margin-bottom: 70px;
            }
        </style>
        <div class="popup-cookie-content">
            <div class="section" style="flex: 1;display: flex;align-items: center;">
                <div class="logo-container">
                    <h2 class="popup-cookie-title">
                        Este site utiliza cookies para melhorar e personalizar a sua experiência de navegação. Para mais informações acesse nossa <a class="policy" href="assets/docs/innon_politica_privacidade.pdf" target="_blank">Política de Privacidade</a>.
                    </h2>
                </div>
            </div>
            <div class="section section-actions">
                <button class="color-hover-opacity cookie-accept" style="margin-left: 10px;">Aceitar para continuar</button>
            </div>
        </div>
    `;

    var idInterval = setInterval(function () {
        var element = $('#sz4IframeForm');
        if (element !== undefined) {
            element.addClass('cookie-mb');
            clearInterval(idInterval);
        }
    }, 1000);

    popup.innerHTML = popupContent;

    $('.customer-central-form').append(popup);

    $('.customer-central-form').css({display: 'block'});

    $('.cookie-accept').on('click', function () {
        setCookie('glpd-accept', 1, 2000);
        var element = $('#sz4IframeForm');
        element.removeClass('cookie-mb');
        $('.popup-cookie').css({display: 'none'});
    });

    $('.cookie-not-accept').on('click', function () {
        var element = $('#sz4IframeForm');
        element.removeClass('cookie-mb');
        setCookie('glpd-accept', 0, 2000);
        $('.popup-cookie').css({display: 'none'});
    })

}
