var PlanosCard = {
    cads: $('.card-planos'),
    active: false,

    init: function() {
      this.cads.hover(function(){
        var hidden = $(this).find('.infos .hidden')
        var height = hidden.find('.height').height()

        if ($(this).hasClass('bordered')) {
            $(this).find('.hover-1').addClass('open');
        }
        $(this).height($(this).height() + 300)

        hidden.stop().animate({
          height: height
        }, 200, function() {
          hidden.css({
            'overflow': 'auto',
            'height': 'auto',
          })
        })

      }, function(){
        var card = $(this)
        var hidden = card.find('.infos .hidden')
        var height = hidden.find('.height').height()

        if ($(this).hasClass('bordered')) {
            $(this).find('.hover-1').removeClass('open');
        }
        hidden.css({
          'overflow': 'hidden',
          'height': height,
        })

        hidden.animate({
          height: 0
        }, 200, function(){
          card.css({
            'height': 'auto'
          })
        })
      })
    }
  }

  PlanosCard.init()
